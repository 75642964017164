<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">{{$t('hotel-location')}}</h4>
    </template>
    <template v-slot:body>
      <div class="about-info m-0 p-0">
        <b-row>
          <b-col cols="12">
            <GmapMap
              :center="mapCenter"
              :zoom="mapZoom"
              style="width: 100%; height: 400px;border-radius: 10px;overflow: hidden;"
            >
              <GmapMarker
                v-if="selectedLocation"
                :position="selectedLocation"
              />
            </GmapMap>
          </b-col>
        </b-row>
        <b-row>
          <ul>
            <li v-if="hotel.governorate">
              <span>
                {{$t('governorates')}} :
              </span>
              <span>
                {{ hotel.governorate[`${$i18n.locale}_name`] }}
              </span>
            </li>
            <li v-if="hotel.city">
              <span>
                {{$t('city')}} :
              </span>
              <span>
                {{ hotel.city[`${$i18n.locale}_name`] }}
              </span>
            </li>
            <li>
              <span>
                {{$t('address')}} :
              </span>
              <span>
                {{ hotel.address }}
              </span>
            </li>
          </ul>
        </b-row>
      </div>
    </template>
  </iq-card>
</template>
<script>
export default {
  data () {
    return {

      mapZoom: 15
    }
  },
  computed: {
    mapCenter () {
      return {
        lat: parseFloat(this.hotel.lat),
        lng: parseFloat(this.hotel.long)
      }
    },
    selectedLocation () {
      return {
        lat: parseFloat(this.hotel.lat),
        lng: parseFloat(this.hotel.long)
      }
    }
  }
}
</script>
