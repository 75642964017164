<template>
  <b-col sm="12">
    <iq-card body-class=" profile-page p-0">
      <template v-slot:body>
        <div class="hotel-info">
          <h3>
            {{ hotel.title }}
          </h3>
          <div class="hotel-info__sub">
            <span>
              {{ hotel.address }}
            </span>
            <span>
              |
            </span>
            <span>
              {{ hotel.start_price }} $ - {{ hotel.end_price }} $
            </span>
            <span>
              |
            </span>
            <div class="text-center">
              <span v-for="star in hotel.stars" :key="star">
                <i class="ri-star-fill" style="color: gold"></i>
              </span>
            </div>
            <span>
              |
            </span>
            <div class="text-center">
                {{ hotel.total_views }} {{$t('views')}}
            </div>
          </div>

          <div class="hotel-info__details">
            <div class="row">
              <div class="col-md-4">
                <img :src="hotel.image" width="100" class="hotel-info__thumbnail"/>
              </div>
            <div class="col-md-8">
              {{ hotel[`${$i18n.locale}_description`] }}
            </div>
            </div>
          </div>
        </div>
      </template>
    </iq-card>
  </b-col>
</template>
<script>
export default {
  mounted () {
    this.getHotel()
    this.getHotelImg()
  }
}
</script>
<style lang="scss">
.hotel-info {
  padding: 10px;
  &__sub {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  &__details {
    margin-top: 10px;
  }
  &__thumbnail {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
  }
  @media (max-width: 600px) {
    &__sub {
      font-size: 10px;
    }
  }
}

</style>
