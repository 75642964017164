<template>
    <b-container fluid>
      <b-row>
        <LayoutHeader />
        <b-col sm="12">
          <b-row>
            <b-col lg="9" class="profile-center">
              <iq-card>
                <template v-slot:headerTitle>
                  <div class="page-title w-100 d-flex alig-items-center justify-content-between">
                    <h4 class="card-title">{{$t('hotel-rooms')}}</h4>
                  </div>
                </template>
                <template v-slot:headerAction>
                  <router-link to="/add-rooms" variant="primary" class="btn btn-primary mx-3" @click="addInit()">{{$t('new-room')}}</router-link>
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col sm="6" md="4" class="mb-2" v-for="(room,index) in rooms.data" :key="index">
                            <div class="rooms-card " @click="goRoomDetails(room)" :style="{backgroundImage: ''}">
                              <div class="img-container w-100 h-100">
                                <img :src="room.image_url || 'https://images.unsplash.com/photo-1611892440504-42a792e24d32?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'" alt="">
                              </div>
                              <div class="info bg-white shadow p-2">
                                  <h6 class="font-weight-bold d-flex align-items-center justify-content-between">
                                      {{room.name}}
                                      <ul class="d-flex align-items-center p-0 m-0">
                                            <li >
                                                <span><i class="fas fa-baby"></i></span>
                                                <span class="mx-1">{{ room.child_number }}</span>
                                            </li >
                                            <li class = "mx-1">
                                                <i class="fas fa-male"></i>
                                                <span class="mx-1">{{ room.adult_number }}</span>
                                            </li>
                                        </ul>
                                  </h6>
                                  <ul class="p-0 m-0">
                                            <li class="py-1 px-2 bg-light border-bottom d-flex align-items-center justify-content-between">
                                                <span><i class="fas fa-receipt"></i> {{$t('total-reverse')}} : </span>
                                                <span class="mx-1"> {{ room.total_reserve }}</span>
                                            </li>
                                            <li class="py-1 px-2 bg-light border-bottom d-flex align-items-center justify-content-between">
                                                <span><i class="far fa-eye"></i> {{$t('views')}} : </span>
                                                <span class="mx-1"> {{ room.total_views }}</span>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="3" class="profile-right">
              <MapCard />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
import MapCard from '@/components/hotelsComponents/MapCard.vue'
export default {
  components: {
    LayoutHeader,
    MapCard
  },
  data () {
    return {
      imgPreview: '',
      payload: {
        img: '',
        number: '',
        audit_number: '',
        bathroom_number: '',
        kitchen_number: '',
        apartment_type: 0,
        room_number: '',
        smoking_policy: 0,
        is_room_near_pool: 0,
        floor_id: ''
      }
    }
  },
  methods: {
    goRoomDetails (room) {
      this.$router.push(`/room-details/${room.id}`)
    }
  },
  mounted () {
    this.getHotel().then(() => { })
    this.getFloors()
    this.getRooms()
  }
}
</script>
<style>

.rooms-card {
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.rooms-card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.rooms-card .info {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-radius: 5px;

}

.imgs-container{
    column-count: 3;
    column-gap: 5px;
}
.add-new-img {
    font-size: 18px;
    font-weight: bold;
    color: #ccc;
    height: 200px;
    border: 2px dashed #ccc;
    cursor: pointer;
}
.img-preview {
    height: 200px;
    border: 2px dashed #ccc;
}
.img-preview img {
    object-fit: contain;
}

</style>
